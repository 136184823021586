// chatSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios'; 
import uuidv4 from '../../utils/uuidv4';
import dayTimeSalutation from '../../utils/utils';

// Thunk para iniciar a sessão
export const startSession = createAsyncThunk(
  'chat/startSession',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/start-session');
      const  conversationId  = response.data.session_id;
      return conversationId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createMessage = createAsyncThunk(
  'chat/createMessage',
  async (message , { rejectWithValue }) => {
    try {
      const response = await axios.post('/message', message);
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const fetchRagAnswer = createAsyncThunk(
  'chat/fetchRagAnswer',
  async ({ message, history, conversationId }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/query', { query: message, chat_history: history, conversation_id: conversationId }, {
        headers: { 'Content-Type': 'application/json' }
      });
      const answer = response.data.answer;
      return answer;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  conversation: {
    id: null, 
    messages: [
      {
        message_id: "410b067a-98f6-4334-b803-5c44b9f4101d",
        senderId: "bot",
        body: `${dayTimeSalutation()} Alguma dúvida sobre o Leadfy?`,
        contentType: "text",
        attachments: [],
        isSaved: true,
        createdAt: new Date(),
      }
    ],
    receivedMessage: null,
    participants: [
      {
        id: "bot",
        role: "bot",
        name: "LeadfyAI",
        lastActivity: "2024-09-09T21:48:53+00:00",
      }
    ],
    unreadCount: 0,
    type: '',
  },
  isLoading: false,
  error: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    sendMessage: (state, action) => {
      state.conversation.messages.push({
        ...action.payload,
        isSaved: false,
      });
    },
    setLoadingMessage: (state, action) => {
      state.conversation.messages.push(action.payload);
    },
    setReceivedMessage: (state, action) => {
      state.receivedMessage = action.payload;
    },
    clearReceivedMessage: (state) => {
      state.receivedMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startSession.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(startSession.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.conversation.id = action.payload;
      })
      .addCase(startSession.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(createMessage.fulfilled, (state, action) => {
        const messageIndex = state.conversation.messages.findIndex(
          (msg) => msg.message_id === action.meta.arg.message_id
        );
        
        if (messageIndex !== -1) {
          state.conversation.messages[messageIndex].isSaved = true;
        }

      })
      .addCase(createMessage.rejected, (state, action) => {
        const messageIndex = state.conversation.messages.findIndex(
          (msg) => msg.message_id === action.meta.arg.message_id
        );

        if (messageIndex !== -1) {
          state.conversation.messages[messageIndex].isSaved = true;
          state.conversation.messages[messageIndex].body = "Erro ao enviar mensagem";
        }
      })
      .addCase(fetchRagAnswer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRagAnswer.fulfilled, (state, action) => {
        state.isLoading = false;
        const loadingMessageIndex = state.conversation.messages.findIndex(
          msg => msg.loadingMessage === true
        );
        if (loadingMessageIndex !== -1) {
          state.conversation.messages[loadingMessageIndex] = {
            conversationId: state.conversation.id,
            message_id: uuidv4(),
            senderId: "bot",
            body: action.payload,
            contentType: "text",
            createdAt: new Date(),
            isSaved: true
          };
        }
      })
      .addCase(fetchRagAnswer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { sendMessage, setLoadingMessage, setReceivedMessage } = chatSlice.actions;
export default chatSlice.reducer;
